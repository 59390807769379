import React from 'react'
import Spacer from '../../components/spacer/Spacer';
import BaseLayout from '../../layouts/BaseLayout';

export default function vision() {
    return (
        <BaseLayout>
            
        </BaseLayout>
    )
}
